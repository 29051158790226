exports.onServiceWorkerUpdateReady = () => {//TODO
    const answer = window.confirm(
        `This application has been updated. ` +
        `Reload to display the latest version?`
    )
    if (answer === true) {
        localStorage.clear(); //TODO - should be more exact :)
        window.location.reload()
    }
}
/*
exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }
}*/

exports.onServiceWorkerActive = () => {
    //TODO -  preload "webflow" assets - not cached on first load and obviously not handled by plugin, do in PWA directly
};

exports.onInitialClientRender = () => {
    // dirty fix for missing popstate listener
    if (process.env.NODE_ENV !== `production`) {
        const GATSBY_NAVIGATE = window.___navigate || {}

        window.addEventListener('popstate', () =>
            GATSBY_NAVIGATE(window.location.pathname, { replace: true })
        )
    }
}